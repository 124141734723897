/* eslint-disable vue/no-unused-vars */
<template>
  <div class="record">
    <div class="_title" v-if="title">我的订单</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4">
        <v-card-text class="pa-0">
          <v-data-table
          v-show="flag"
            :headers="headers"
            :items="desserts"
            disable-sort
            disable-filtering
            :items-per-page="desserts.length"
            class="elevation-1"
            no-data-text="暂无数据"
            hide-default-footer
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:item.chargeChannel="{ item }">
              <span v-if="item.chargeChannel == 1">微信</span>
              <span v-if="item.chargeChannel == 2">支付宝</span>
              <span v-if="item.chargeChannel == 3">银联</span>
                 <span v-if="item.chargeChannel == 4">线下银行卡</span>
            </template>
          
            
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="item.invoiceStatus==0" style="color:#1976d2;" small class="mr-2" @click="getInvoiceData(item)">
                开发票
              </v-icon>
               <v-icon  v-else  style="color:#1976d2;" small class="mr-2" @click="invoiceDialog(item)">
                查看发票
              </v-icon>
             
            </template>
          </v-data-table>
          <invoice ref='invoices' v-show="!flag" @success='success'></invoice>
        </v-card-text>
      </v-card>
    </div>

    

    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="record-dialog">
        <v-card-title>
          <span class="text-h5">我的发票</span>
        </v-card-title>

        <v-card-text>
          <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">发票类型：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">
                  <span v-if="invoiceData.invoiceType == 0">普票</span>
                  <span v-if="invoiceData.invoiceType == 1">专票</span>
                    <span v-if="invoiceData.invoiceType == 10">增值税普通(电子)发票</span>
                      <span v-if="invoiceData.invoiceType == 20">增值税普通(纸质)发票</span>
                        <span v-if="invoiceData.invoiceType == 30">增值税专用(纸质)发票</span>
                </div>
                </v-col
              >
            </v-row>
          </div>
      
          <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">获取方式：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">
                   <span v-if="invoiceData.sendType == 1">电子邮箱</span>
                    <span v-if="invoiceData.sendType == 2">会场自取</span>
                     <span v-if="invoiceData.sendType == 3">快递邮寄</span>
                  </div></v-col
              >
            </v-row>
          </div>

          <div v-if="invoiceData.sendType == 1" class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">电子邮箱：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.mailbox }}</div></v-col
              >
            </v-row>
          </div> 

           <div v-if="invoiceData.sendType == 3" class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">邮寄地址：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.address }}</div></v-col
              >
            </v-row>
          </div> 
           <div v-if="invoiceData.sendType == 3" class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">联系人姓名：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.contactName }}</div></v-col
              >
            </v-row>
          </div> 
           <div v-if="invoiceData.sendType == 3" class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">联系人手机号：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.contactPhone }}</div></v-col
              >
            </v-row>
          </div> 
          
          <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">单位/公司名称：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.companyName }}</div>
              </v-col>
            </v-row>
          </div>

          <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">统一社会信用代码：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.socialCreditCode }}</div>
              </v-col>
            </v-row>
          </div>

           <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">单位/公司注册地址：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.companyAddress }}</div>
              </v-col>
            </v-row>
          </div>

          <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">电话：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.phone }}</div>
              </v-col>
            </v-row>
          </div>

           <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">开户行名称：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.bank }}</div>
              </v-col>
            </v-row>
          </div>

             <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">银行账号：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.account }}</div>
              </v-col>
            </v-row>
          </div>

           <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">发票备注：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.remarks }}</div>
              </v-col>
            </v-row>
          </div>

           <div class="group">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">发票状态：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">

                <span v-if="invoiceData.invoiceStatus == 0">未开票</span>
                <span v-if="invoiceData.invoiceStatus == 1">开票中</span>
                <span v-if="invoiceData.invoiceStatus == 2">已开票</span>
                <span v-if="invoiceData.invoiceStatus == 3">已拒绝</span>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="group" v-if="invoiceData.invoiceStatus == 3">
            <v-row>
              <v-col cols="12" sm="12" xs="12" lg="4" xl="4" md="4">
                <div class="label">拒绝原因：</div></v-col
              >
              <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
                <div class="value">{{ invoiceData.invoiceReason }}</div>
              </v-col>
            </v-row>
          </div>

          
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="dialog = false">
            确定
          </v-btn>
           <v-btn v-if="invoiceData.invoiceStatus==3" color="blue darken-1" text @click="getInvoiceData(invoiceData)">
            再次开票
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
  </div>
</template>

<script>
import invoice from '@/views/invoice';

export default {
  components:{invoice},
  data() {
    return {
      valid: true,
      dialog: false,
      invoiceData: {},
      flag:true,
      listFlag:false,
      headers: [
        {
          text: "订单号",

          value: "id",
        },
        { text: "支付方式", value: "chargeChannel" },
        { text: "支付类型", value: "productName" },
        { text: "支付金额", value: "price" },
     
        { text: "支付时间", value: "createTime" },
        { text: "操作", value: "actions", sortable: false },
      ],
      desserts: [],
    };
  },
  created() {
    this.getList();
  
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
  },

  methods: {
   success(){
     this.flag=true
     if(this.listFlag){
       return
     }
     this.getList();
       
   },
   invoiceDialog(item){
      this.invoiceData = Object.assign({}, item);
      this.dialog = true;
   },
    getInvoiceData(item) {
      // console.log(111);
   this.invoiceData = Object.assign({}, item);
   this.$refs.invoices.init(this.invoiceData)
   this.flag=false
     this.dialog = false;
         

    },
    getList() {
      this.listFlag=true
      this.desserts = [];
      this.$request({
        url: "/order/list",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.code == 200) {
           
          res.data.map((item) => {
            if (item.payStatus == 1) {
              this.desserts.push(item);
            }
          });
        }
        this.listFlag=false
      });
    },
    costAction() {},
  },
};
</script>

<style lang="less">
.record-dialog {
  .v-application .text-h5,
  #inspire,
  .record-dialog,
  .text-h6,
  .text-h5 {
    font-size: 20px !important;
    line-height: 30px;
    font-weight: bold;
  }
  .group {
    font-size: 15px !important;
    display: flex;

    margin-top: 10px;
    line-height: 20px;
    .label {
      width: 155px;
      font-weight: bold;
      font-size: 16px;
    }
    .value {
    }
  }
  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-auto{
    padding: 10px;
  }
}
.record {
  padding: 0 10px;
  padding-bottom: 150px;

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-footer {
    font-size: 15px !important;
  }
  .v-data-table__mobile-row__header {
    font-size: 15px !important;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  .form-title {
    font-size: 18px;
    background: #efefef;
    color: #3b86aa;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
