<template>
  <div class="cost">
    
    <div class="_content" :class="className">
      <v-card flat class="mt-4">
        <v-card-text class="pa-0">
         
          <div class="form-title">发票信息：</div>
          <v-form
            ref="form2"
            v-model="valid"
            lazy-validation
            :style="formStyle"
          >
            <div class="d-flex align-center">
              <div
                class="label mr-3 required"
                :class="$store.state.navigation ? 'w250' : 'w170'"
              >
                发票类型：
              </div>
              <v-radio-group  @change='radioChange' v-model="cost.invoiceType" row>
                <v-radio label="增值税普通(电子)发票" value="10"></v-radio>
                <v-radio label="增值税普通(纸质)发票" value="20"></v-radio>
                <v-radio label="增值税专用(纸质)发票" value="30"></v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex align-center">
              <div
                class="label mr-3 required w170"
           
              >
                获取方式：
              </div>
              <v-radio-group  @change='radioChange2' v-model="cost.sendType" row>
                <v-radio v-if="cost.invoiceType==10" label="电子邮箱" value="1"></v-radio>
             
                <v-radio v-if="cost.invoiceType!=10" label="快递邮寄" value="3"></v-radio>
                <v-radio v-if="cost.invoiceType!=10" label="会场自取" value="2"></v-radio>
               
              </v-radio-group>
            </div>
             <div class="d-flex align-center" v-if="cost.sendType==2" >
              <div
                class="label"
                :style='$store.state.navigation?"width:130px;":"width:220px;"'
              >
              </div>
              <div style='color:red;font-size:12px;'>
                缴费开票时间不得晚于11月15日
              </div>
            </div>
            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
              
              >
                邮寄地址：
              </div>
              <v-text-field
                v-model="cost.address"
                :rules="rules.address"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
             
              >
                联系人姓名：
              </div>
              <v-text-field
                v-model="cost.contactName"
                :rules="rules.contactName"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
               
              >
                联系人手机号：
              </div>
              <v-text-field
                v-model="cost.contactPhone"
                :rules="rules.contactPhone"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.invoiceType==10">
              <div
                class="label required w170"
             
              >
                电子邮箱：
              </div>
              <v-text-field
                v-model="cost.mailbox"
                :rules="rules.mailbox"
                required
              />
            </div>
           

            <div class="d-flex justify-center align-center">
              <div
                class="label w170 required"
              
              >
                单位/公司名称：
              </div>
              <v-text-field v-model="cost.companyName"   :rules="rules.companyName" />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label required w170"
               
              >
                统一社会信用代码：
              </div>
              <v-text-field
                v-model="cost.socialCreditCode"
                :rules="rules.socialCreditCode"
                required
              />
            </div>

            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
                :class="{required:cost.invoiceType==30}"
              >
                单位/公司注册地址：
              </div>
              <v-text-field
                v-model="cost.companyAddress"
                :rules="cost.invoiceType==30?rules.companyAddress:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
               :class="{required:cost.invoiceType==30}"
              >
                电话：
              </div>
              <v-text-field
                v-model="cost.phone"
                :rules="cost.invoiceType==30?rules.phone:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
               :class="{required:cost.invoiceType==30}"
              >
                开户行名称：
              </div>
              <v-text-field v-model="cost.bank" :rules="cost.invoiceType==30?rules.bank:rules.empty" required />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
              :class="{required:cost.invoiceType==30}"
              >
                银行账号：
              </div>
              <v-text-field
                v-model="cost.account"
                :rules="cost.invoiceType==30?rules.account:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
              
              >
                发票备注：
              </div>
              <v-text-field
                v-model="cost.remarks"
         
              />
            </div>
          </v-form>
         
          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            :disabled="!valid"
            color="success"
            block
            class="mt-4"
            @click="costAction"
          >
            提交发票信息
          </v-btn>
        
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      list: [],
      cost: {
     
      
        invoiceOrg: "",
        taxLabel: "",
        invoiceType: "10",
        socialCreditCode: "",
        companyName: "",
        address: "",
        phone: "",
        bank: "",
        account: "",
        contactName:"",
        mailbox:"",
        companyAddress:"",
        sendType:"1",
        contactPhone:"",
        remarks:''
      },
      rules: {
       
        invoiceOrg: [(v) => !!v || "请输入发票抬头"],
        taxLabel: [(v) => !!v || "请输入纳税人识别号"],
        invoiceType: [(v) => !!v || "请选择发票类型"],
        socialCreditCode: [(v) => !!v || "请输入统一社会信用代码"],
        address: [(v) => !!v || "请输入邮寄地址"],
        phone: [(v) => !!v || "请输入电话"],
        bank: [(v) => !!v || "请输入开户行名称"],
        account: [(v) => !!v || "请输入银行账号"],
         sendType: [(v) => !!v || "请选择获取方式"],
          contactName: [(v) => !!v || "请输入联系人姓名"],
           mailbox: [(v) => !!v || "请输入电子邮箱"],
            companyAddress: [(v) => !!v || "请输入单位/公司注册地址"],
            companyName: [(v) => !!v || "请输入单位/公司名称"],
             contactPhone: [(v) => !!v || "请输入联系人手机号"],
             
         
        empty:[true]
      },
    };
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 100%; ";
        case "sm":
          return "width: 100%; ";
        case "md":
          return "width: 100%; ";
        case "lg":
          return "width: 90%; ";
        case "xl":
          return "width: 70%; ";
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    mlClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "ml-2";
        case "sm":
          return "ml-12";
        case "md":
          return "ml-100";
        case "lg":
          return "ml-100";
        case "xl":
          return "ml-100";
      }
    },
  },
  created() {
    
  },
  methods: {
    clear() {
      this.$refs.form2.reset();
    },
    init(val){
      console.log(val)
        this.cost=val
        this.cost.invoiceType=String(val.invoiceType)
         this.cost.sendType=String(val.sendType)
        if(this.cost.invoiceType==''){
             
            this.cost.invoiceType=""
        }
        if(this.cost.sendType==''||!this.cost.sendType){
            this.cost.sendType="1"
        }
        this.cost.orderId=val.id
      
    },
    resetForm(){
      this.cost=Object.assign(this.cost,{
        taxLabel: "",
        socialCreditCode: "",
        companyName: "",
        address: "",
        phone: "",
        bank: "",
        account: "",
        contactName:"",
        mailbox:"",
        companyAddress:"",
        contactPhone:"",
        remarks:""
        
      })
    },
    radioChange(val){
      if(val==10){
        this.cost.sendType="1"
      }else{
         this.cost.sendType="3"
      }
      this.resetForm()
       this.$refs.form2.resetValidation()
         this.$forceUpdate()
        
    },
    radioChange2(){
        
      this.resetForm()
       this.$refs.form2.resetValidation()
       this.$forceUpdate()
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();

      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  
    costAction() {
   
  
       
      if (this.$refs.form2.validate()) {
        this.$request({
          url: "/order/update",
          method: "post",
          data: this.cost,
        }).then((res) => {
            console.log(res)
             this.$emit('success')
        this.$message.tips("提交成功");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cost {
  padding: 0 10px;
  padding-bottom: 150px;
 
  .w250 {
    width: 260px !important;
    text-align: end;
  }

  .w170 {
    width: 180px !important;
    text-align: end;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  .form-title {
    font-size: 18px;
    background: #efefef;
    color: #3b86aa;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
